import { useRef, useState } from 'react';
import classes from './Subheading.module.css';

import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config';

const Subheading = ({ children, item, group }) => {
  const itemNameRef = useRef();
  const [working, setWorking] = useState(false);
  const [editName, setEditName] = useState(false);

  const handleKeyDown = (event) => {
    //submit form if enter key is pressed

    if (event.key === 'Enter') {
      handleNameChange();
      setEditName(false)
    }
  }
  const handleNameChange = async () => {
    if (working) return;
    setEditName(false)

    const itemName = itemNameRef.current.value;

    //avoid duplicate items
    if (itemNameRef.current.value !== item.name) {

      const LCItems = group.items.map(item => item.selected && item.organise.length > 0 && item.name.toLowerCase());
      if (LCItems.indexOf(itemName.toLowerCase()) !== -1) {
        alert('Doh! You already have this item in the list.');
        itemNameRef.current.value = item.name;
        return;
      }
    }

    setWorking(true);
    const itemIndex = group.items.findIndex(i => i.id === item.id);
    const updatedItem = {
      ...group.items[itemIndex],
      name: itemName
    }
    const updatedItems = [...group.items];
    updatedItems[itemIndex] = updatedItem;
    try {
      const groupRef = doc(db, 'groups', group.id);
      await updateDoc(groupRef, {
        items: updatedItems
      });
    } catch (err) {
      alert("There was a problem updating your group.")
    }
    setWorking(false);
  };
  return (
    <div>
      {editName ? (
        <div className={classes.checklistAddGroup}>
          <input
            className={classes.editItem}
            id="itemname"
            onBlur={handleNameChange}
            defaultValue={item.name ? item.name : ''}
            placeholder='Enter item name'
            ref={itemNameRef}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        </div>
      ) : (
        <div className={classes.heading} onClick={() => setEditName(!editName)}>{children}</div>
      )
      }

    </div>
  );
};

export default Subheading;