import React, { useState, useEffect, useMemo } from 'react';
import classes from './OnboardingTutorial.module.css';

import Loading from '../UI/Loading';
import Button from '../UI/Button';

import { doc, updateDoc } from 'firebase/firestore';
import { db, TUTORIALS } from '../../config';
import img1 from '../../assets/tutorials/tutorial1.png';
import img2 from '../../assets/tutorials/tutorial2.png';
import img3 from '../../assets/tutorials/tutorial3.png';
import img4 from '../../assets/tutorials/tutorial4.png';
import img5 from '../../assets/tutorials/tutorial5.png';
import img6 from '../../assets/tutorials/tutorial6.png';
import img7 from '../../assets/tutorials/tutorial7.png';
import img8 from '../../assets/tutorials/tutorial8.png';
import img9 from '../../assets/tutorials/tutorial9.png';

const OnboardingTutorial = ({ userId }) => {

    const [screenNum, setScreenNum] = useState(1);
    //console.log('screenNum',screenNum,'TUTORIALS.length',TUTORIALS.length);
    const [tutorial, setTutorial] = useState();

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50 

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }
    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)
    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe) { changeScreen('up');}
        if (isRightSwipe) { changeScreen('down');}
    }

    const finishTutorial = async () => {

         //update user details to say they have completed onboarding
         try {
            const userRef = doc(db, 'users', userId);
            await updateDoc(userRef, { 
                onboarding: true
            });
        } catch (err) {
            console.log('There was a problem updating the user onboarding');
        }

    }

    const RenderDot = ( { num } ) => {
        return (
            <div className={`${classes.tutorialDot} ${num === screenNum ? classes.tutorialDotActive : classes.tutorialDotInactive}`}>
               
            </div>
        )
    }

    const changeScreen = (dir) => {
        let num = screenNum;
        if (dir === 'up') {
            if ( screenNum < TUTORIALS.length ){
                num++;
                setScreenNum(num)
            } else if (screenNum === TUTORIALS.length) { 
                finishTutorial()
            }
        } else {
            if ( screenNum > 1 ){
                num--;
                setScreenNum(num)
            }
        }
        
    }

    const screenImage = useMemo(() => {
        //list and organise items according to sort mode 
        let img = null;
        switch (screenNum) {
            case 1: img = img1; break;
            case 2: img = img2; break;
            case 3: img = img3; break;
            case 4: img = img4; break;
            case 5: img = img5; break;
            case 6: img = img6; break;
            case 7: img = img7; break;
            case 8: img = img8; break;
            case 9: img = img9; break;
            default:
        }
        return img;
    }, [screenNum]);

    useEffect(() => {
        if (screenNum > 0) setTutorial( TUTORIALS[screenNum-1] );
      }, [screenNum]);

    {/*Called from Root. Shows onboarding tutorial screens. */}

    if (!tutorial) return (<Loading/>);

    return (
      <div 
      className={`container ${classes.tutorialContainer} ${classes.tutorialBg}`}
      onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>

        <div className={classes.tutorialImageHolder} onClick={()=>changeScreen('up')}>
            <img src={screenImage} className={classes.tutorialImage} alt=""/>
        </div>

        <div className={classes.tutorialContent} onClick={()=>changeScreen('up')}>
            
            <div className={classes.tutorialTextHolder}>
                <div className={classes.tutorialHeading}>{tutorial.title}</div>
                <div className={classes.tutorialTagline}>{tutorial.tagline}</div>
            </div>
       
            <div className={classes.footerContent}>

                <div className={classes.tutorialDots}>
                    { TUTORIALS.map ( (t, index) => <RenderDot key={ index } num={ index+1 }/> ) }
                </div>

                { screenNum === TUTORIALS.length ? 
                    <Button className='primary' onClick={finishTutorial}>
                        Get started
                    </Button> :
                    <Button className='borderless' onClick={finishTutorial}>
                        Skip
                    </Button>
                }

            </div>

        </div>

      </div>


    );
  };
  
  export default OnboardingTutorial;