
import classes from './Share.module.css';
import Avatar from '../Group/Avatar';


const ShareSubItem = ( { item, length, group, ind, subitem } ) => {

    //render item number 
    const itemNumber = length>1 ? `#${ind+1}` : null;

    //render name of person assigned to this sub item
    const assignedMember = group.members.find(member => ( member.id === subitem.assigned));
    
    //if (item.name==="Plates") console.log(item, subitem)

    return (
       
       <div className={classes.shareSubItemHolder} >

            {itemNumber}
            {
            /*{assignedMember && <Avatar member={assignedMember}/>}
            {!assignedMember && <div  className={classes.circle} > ? </div>}
            {assignedMember ? `${assignedMember.name} will ` :'Someone needs to '} */
            }
            
            {` ${subitem.method ? subitem.method : 'Organise'} `}
    
        </div>

    );

}

export default ShareSubItem;