import classes from './Avatar.module.css';

import avatar1 from '../../assets/avatars/buy-avatar.svg';
import avatar2 from '../../assets/avatars/bring-avatar.svg';
import avatar3 from '../../assets/avatars/borrow-avatar.svg';
import avatar4 from '../../assets/avatars/hire-avatar.svg';

const MethodAvatar = ({ avatarId, showName, selectedAvatar, avatar, avatarSize, style, isSelected, setSelected }) => {
  const avatarName = null;

  let imgSizeClass;
  switch (avatarSize) {
    case 'small': imgSizeClass = classes.imgSmall; break;
    case 'medium': imgSizeClass = classes.imgMedium; break;
    case 'large': imgSizeClass = classes.imgLarge; break;
    case 'huge': imgSizeClass = classes.imgHuge; break;
    default: imgSizeClass = classes.imgMedium;
  }

  let image;
  switch (avatarId?.toLowerCase()) {
    case 'buy': image = avatar1; break;
    case 'bring': image = avatar2; break;
    case 'borrow': image = avatar3; break;
    case 'hire': image = avatar4; break;
    default: image = null;
  }

  if (!avatarId) return;

  return (

    <div
      className={classes.avatarContainer}
      style={style}
      onClick={setSelected}
    >

      {image && <img
        src={image}
        className={`
                      ${classes.img} 
                      ${imgSizeClass} 
                      ${selectedAvatar && (avatarId === selectedAvatar) && classes.selectedImg}
                      ${isSelected && classes.selectedImg}
                    `}
        alt=''
      />
      }

      {showName &&
        <div
          className={`
                      ${classes.text} 
                      ${selectedAvatar && (avatarId === selectedAvatar) && classes.selectedText}
                      ${isSelected && classes.selectedText}
                    `}
        >
          {showName === 'long' ? avatarName : avatarName?.substr(0, 3)}
        </div>
      }
    </div>
  );
}

export default MethodAvatar;