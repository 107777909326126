export const AVATARS=[
  {
    "id": "1",
    "name": "Kangaroo",
    "info": "Info about this animal"
  },
  {
    "id": "2",
    "name": "Wombat",
    "info": "Info about this animal"
  },
  {
    "id": "3",
    "name": "Lizard",
    "info": "Info about this animal"
  },
  {
    "id": "4",
    "name": "Murray cod",
    "info": "Info about this animal"
  },
  {
    "id": "5",
    "name": "Possum",
    "info": "Info about this animal"
  },
  {
    "id": "6",
    "name": "Quokka",
    "info": "Info about this animal"
  },
  {
    "id": "7",
    "name": "Tassie devil",
    "info": "Info about this animal"
  },
  {
    "id": "8",
    "name": "Galah",
    "info": "Info about this animal"
  },
  {
    "id": "9",
    "name": "Koala",
    "info": "Info about this animal"
  }
]
