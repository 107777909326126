import classes from './Tab.module.css';

import Subheading from '../UI/Subheading';


const Tab = ({ title, children, closeHandler, item, group, bgGradient }) => {

  return (

    <div className={classes.tabBg} >

      <div className={classes.tabTopSpace} onClick={closeHandler} >&nbsp;</div>

      <div className={`${classes.tabContainer} ${bgGradient && classes.tabGradient}`} id="tab-container">

        <div className={classes.tabTopStripeContainer}>
          <div className={classes.tabTopStripe}>&nbsp;</div>
        </div>

        <Subheading item={item} group={group}>{title}</Subheading>

        {children}

      </div>
    </div>


  )
}

export default Tab;
