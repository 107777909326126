import React, { useState, useMemo, useRef } from 'react';
import classes from './SelectOptions.module.css';
import DescriptionIcon from '@mui/icons-material/Description';
import icon_sort from '../../assets/icons/icon_sort.png';
import icon_share from '../../assets/icons/icon_share.png';
import Button from './Button';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const SelectOptions = ({ title, icon, options, selectedOption, setSelectedOption }) => {

  const [showOptions, setShowOptions] = useState(false);

  const containerRef = useRef();
  const containerDimensions = containerRef?.current?.getBoundingClientRect();
  const yPos = containerDimensions?.y + 20 + 'px';
  const xPos = containerDimensions?.x + 20 + 'px';

  let _icon = null;
  switch (icon) {
    case 'sort': _icon = <div className='actionButton'><img src={icon_sort} alt='Sort' className={classes.icon_sort} /></div>; break;
    case 'share': _icon = <div className='actionButton'><img src={icon_share} alt='Share' className={classes.icon_share} /></div>; break;
    case 'add': _icon = <div className='actionButton bg-primary'><span className={classes.icon_add}>+</span></div>; break;
    case 'more': _icon = <div className='moreCategoryButton'><MoreHorizIcon style={{ fontSize: 20 }} /></div>; break;
    default: _icon = <DescriptionIcon style={{ fontSize: 30 }} />;
  }

  const optionChangeHandler = (id) => {
    setShowOptions(false);
    setSelectedOption(id);
  }

  const renderOptions = useMemo(() => {
    //console.log(title,yPos)
    return (
      <>
        <div className={classes.optionsBgContainer} onClick={() => setShowOptions(!showOptions)}></div>
        <div className={classes.optionsContainer} style={{ 'top': yPos, 'left': xPos }}>
          <div className={classes.optionsHeaderContainer}>
            <Button className='flex' onClick={() => setShowOptions(!showOptions)}>
              <div className={classes.optionsHeaderText}>{title}</div>
            </Button>
          </div>
          <div className={classes.optionsHolder}>
            {options?.map(option => (
              <Button key={option.id} className='flex' onClick={() => optionChangeHandler(option.id)}>
                <div className={`${classes.option} ${(selectedOption === option.id) && classes.selectedOption}`}>
                  {option.title}
                </div>
              </Button>
            ))}
          </div>
        </div>
      </>

    )
  }, [_icon]);


  return (
    <div className={classes.selectionContainer} ref={containerRef}>

      <Button className='noStyle' onClick={() => setShowOptions(!showOptions)}>
        {_icon}
      </Button>

      {showOptions && renderOptions}

    </div>
  );
};

export default SelectOptions;
