import React, { useState, useEffect, useRef } from 'react';
import Avatar from './Avatar';
import './AvatarDropdown.style.css';
import MethodAvatar from './MethodAvatar';

const AvatarDropdown = ({ options, defaultValue, onSelectedValueChange, ddlType }) => {
  let defaultOption = null;
  const unassignedEntry = { key: 'unassigned', member: { id: 'unassigned' }, text: 'Unassigned' };
  if (ddlType === 'avatar') {
    defaultOption = unassignedEntry;
    if (defaultValue === "unassigned") defaultOption = unassignedEntry;
    else defaultOption = options.find(f => f.member.id === defaultValue) || (options && options.length && options[0]);
  } else {
    defaultOption = options.find(f => f.text === defaultValue) || (options && options.length && options[0]);
  }

  const [selectedValue, setSelectedValue] = useState(defaultOption);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (optionValue) => {
    if (ddlType === 'avatar') {
      if (optionValue.member.id === 'null') {
        setIsOpen(true);
        return false;
      }
    }
    setSelectedValue(optionValue);
    setIsOpen(false);
    const payload = ddlType === 'avatar' ? optionValue.member?.id : optionValue?.id;
    onSelectedValueChange(payload);
  };

  const trimSelectedValue = (value) => {
    if (windowWidth < 510 && ddlType === 'avatar') {
      let trimmedValue = value.slice(0, 10);
      return trimmedValue;
    } else {
      return value;
    }
  };


  return (
    <div className="image-dropdown">
      <div
        className={`dropdown ${isOpen ? 'open' : ''}`}
        onClick={toggleDropdown}
        ref={dropdownRef}
      >
        <div className="selected-value">
          {ddlType === 'avatar' && <Avatar member={selectedValue?.member} avatarSize='small' showName={false} />}
          {ddlType === 'method' && <MethodAvatar avatarId={selectedValue?.text} avatarSize='small' showName={false} />}
          <span>{selectedValue?.text && trimSelectedValue(selectedValue?.text)}</span>
          <span className={`arrow ${isOpen ? 'up' : 'down'}`}></span>
        </div>
        <ul className="options">
          {options.map((option, index) => (
            <li key={index} onClick={() => handleOptionClick(option)}>
              {ddlType === 'avatar' && <Avatar key={index} member={option.member} avatarSize='small' showName={false} style={{ marginRight: '8px' }} />}
              {ddlType === 'method' && <MethodAvatar key={index} avatarId={option.text} avatarSize='small' showName={false} style={{ marginRight: '8px' }} />}
              <span>{option?.text}</span>
            </li>
          ))}
          {ddlType === 'avatar' &&
            [
              // {key: 'null', member: { id: 'null' }, text: '----' },
              unassignedEntry,
              // {key: 'null2', member: { id: 'null' }, text: '----' },
              { key: 'add', member: { id: 'add' }, text: 'Add someone' }
            ].map(m => (
              <li key={m.key} onClick={() => handleOptionClick(m)}>
                <span>{m.text}</span>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
};

export default AvatarDropdown;
